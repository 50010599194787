import { useEffect, useState } from 'react';
import './App.css';

import appleIcon from './assets/apple_icon.png';
import googleIcon from './assets/google_icon.png';

import { authentication, googleProvider } from "./firebase/config";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

import { initializeApp } from 'firebase/app';

function App() {

  const firebase = require('./firebase/config');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleGoogleLogin = async () => {
    const googleProviderCustomed = googleProvider.setCustomParameters({
      prompt: "select_account",
    });
    await signInWithPopup(authentication, googleProviderCustomed)
      .then((data) => {
        if (data) {
          console.log('data.user.email',data.user.email)
          const data2 = {
            email: data.user.email,
            password: password,
            secretKey: secret_key,
          }
          const response = fetch("https://api-release.socialseeds.asia:444/public/oauth/login", {
            method: "POST",
            mode: "cors",
            cache: "no-cache", 
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(data2), 
          });
          const resp = response.json();
          if(resp.status == 'success') {
            window.location.replace(resp?.data?.callback_url)
          }
        } else {
          console.log('google login error 3')
        }
      })
      .catch((error) => console.log(error));
  };

  // const firebaseConfig = {
  //   apiKey: 'AIzaSyC3FBKZPExoN8sz8c3cDAAT9rIe-nS8Y20',
  //   authDomain: 'seeds-32f44.firebaseapp.com',
  //   projectId: 'seeds-32f44',
  //   storageBucket: 'seeds-32f44.appspot.com',
  //   messagingSenderId: '819768100442',
  //   appId: '1:819768100442:web:3ca1a82b59af8ee3c23a63',
  //   measurementId: 'G-5LSQMK83RW',
  // };
  
  // const firebaseApp = initializeApp(firebaseConfig);
  // const auth = getAuth(firebaseApp);
  // const provider = new OAuthProvider('apple.com');
  // provider.addScope('email');
  // provider.addScope('name');
  

  const handleAppleLogin = async () => {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('result',result)
        const user = result.user;
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = OAuthProvider.credentialFromError(error);
      });

    // try {
    //   const result = await auth.signInWithRedirect(provider);
    //   // Handle successful sign-in (result.user contains user information)
    //   console.log(result.user);
    // } catch (error) {
    //   // Handle errors
    //   if (error.code === 'auth/popup-closed-by-user') {
    //     console.log('Apple Sign-In popup closed by the user.');
    //   } else {
    //     console.error(error);
    //   }
    // }

  };

  const params = new URLSearchParams(window.location.search);
  const secret_key = params.get('secret_key');

  const handleLogin = async() => {
    try {
      const data = {
        email: email,
        password: password,
        provider: "Api",
        timezone: "Asia/Jakarta",
        fcm_toke: "198237ggac123456s",
        apn_token: "ExponentPushToken[xxxxxxxxxxxxxxxxxxxxxx]",
        latitude: "6.123456",
        longitude: "101.123456",
        data_provider: {}
      }
      const data2 = {
        email: email,
        password: password,
        secretKey: secret_key,
      }
      const response = await fetch("https://api-release.socialseeds.asia:444/public/oauth/login", {
        method: "POST",
        mode: "cors",
        cache: "no-cache", 
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data2), 
      });
      const resp = await response.json();
      if(resp.status == 'success') {
        window.location.replace(resp?.data?.callback_url)
      }
    } catch(error) {
      console.log('error',error)
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Login
      </h2>
    </div>

    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
            Email address
          </label>
          <div className="w-full flex items-center justify-between rounded-md border-0 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2">
            <input
              placeholder="Enter your log in Email"
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="w-full outline-none border-none focus:ring-transparent bg-transparent focus:bg-transparent"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Password
            </label>
          </div>
          <div className="w-full flex items-center justify-between rounded-md border-0 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2">
            <input
              placeholder="Enter your password"
              id="password"
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              autoComplete="current-password"
              required
              className="w-[90%] outline-none border-none focus:ring-transparent bg-transparent focus:bg-transparent"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="cursor-pointer pr-5" onClick={togglePasswordVisibility}>
              {isPasswordVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>

        <div>
          <button type="submit" onClick={handleLogin} className="flex w-full justify-center rounded-full bg-[#60A78D] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Login
          </button>
        </div>
      </div>

      {/* <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h3 className="mt-10 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
          OR
        </h3>
      </div> */}
      {/* <div className="space-y-3 mt-5">
        <button className="flex gap-3 w-full justify-center rounded-full bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border-black border-2"
        onClick={handleGoogleLogin}>
          <img src={googleIcon} /> Login with Google
        </button>
        <button className="flex gap-3 w-full justify-center rounded-full bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border-black border-2"
        onClick={handleAppleLogin}>
          <img src={appleIcon} /> Login with Apple
        </button>
      </div> */}


    </div>
  </div>

  );
}

export default App;
